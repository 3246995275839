import * as React from 'react';
import { Link } from 'react-scroll';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Bulb from '../images/icons/bulb.svg';
import CodeSlash from '../images/icons/code-slash.svg';
import HomeScreen from '../images/icons/home-screen.svg';
import Timelapse from '../images/icons/timelapse.svg';
// import Abc from '../images/logos/abc.svg';
import BoardHealthScore from '../images/logos/board-healthscore.svg';
import Entelligent from '../images/logos/entelligent.png';
import FarmingFirst from '../images/logos/farming-first.svg';
import Lavit from '../images/logos/lavit.svg';
import MindfulEscapes from '../images/logos/mindful-escapes.svg';
import MuchSmarter from '../images/logos/much-smarter.svg';
import NeueHouse from '../images/logos/neuehouse.svg';
import PlanitEasy from '../images/logos/planiteasy.png';
import Sprout from '../images/logos/sprout.svg';
import Unsnctnd from '../images/logos/unsnctnd.svg';
import Wax from '../images/logos/wax.svg';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <main className={`index`} id={`top`}>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top">
        <div className="container-fluid">
          <a className="navbar-brand" style={{ fontWeight: 500 }} href="#top">
            Understated
          </a>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav ms-auto mb-0 fs-6"
              style={{ letterSpacing: '.01rem' }}
            >
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="work"
                  spy={true}
                  hashSpy={true}
                  offset={-232}
                  duration={500}
                >
                  Work
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="ventures"
                  spy={true}
                  hashSpy={true}
                  offset={-155}
                  duration={500}
                >
                  Ventures
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="services"
                  spy={true}
                  hashSpy={true}
                  offset={-155}
                  duration={500}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="tools"
                  spy={true}
                  hashSpy={true}
                  offset={-155}
                  duration={500}
                >
                  Tools
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link pe-0"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  hashSpy={true}
                  duration={500}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header>
        <div
          className={`header-content d-flex flex-column justify-content-center pt-6 pb-7 pt-md-7 pb-md-8`}
        >
          <h1 className={`display-1 mb-0`}>
            A digital consultancy building for growth
          </h1>
        </div>
      </header>

      <section id={`work`}>
        <h2 className={`mb-4`}>Work</h2>

        <ul className={`list-unstyled mb-0 row text-center`}>
          <li className={`us-card mb-3`}>
            <a
              href={`https://wax.insure/`}
              aria-label={`WAX`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={Wax} alt={`Wax`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-3`}>
            <a
              href={`https://www.muchsmarter.com/`}
              aria-label={`Much Smarter`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={MuchSmarter} alt={`Much Smarter`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-3`}>
            <a
              href={`https://planiteasy.com/`}
              aria-label={`PlanitEasy`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={PlanitEasy} alt={`PlanitEasy`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-0`}>
            <a
              href={`https://boardhealthscore.com/`}
              aria-label={`Board HealthScore`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={BoardHealthScore} alt={`Board HealthScore`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-3`}>
            <a
              href={`https://www.unsnctnd.com/`}
              aria-label={`UNSNCTND`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={Unsnctnd} alt={`UNSNCTND`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-3`}>
            <a
              href={`https://www.sprouthealthgroup.com/`}
              aria-label={`Sprout Health`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={Sprout} alt={`Sprout Health`} />
              </div>
            </a>
          </li>
          {/*<li className={`us-card mb-0`}>
            <a
              href={`https://advisoryboardcrystals.com/`}
              aria-label={`ABC.`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={Abc} alt={`ABC.`} />
              </div>
            </a>
            ABC.
          </li>*/}
          <li className={`us-card mb-3`}>
            <a
              href={`https://mindfulescapes.com/`}
              aria-label={`Mindful Escapes`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={MindfulEscapes} alt={`Mindful Escapes`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-0`} style={{ opacity: '50%' }}>
            <a
              style={{ cursor: 'wait !important' }}
              href={`#comingSoon`}
              aria-label={`Coming Soon`}
            >
              <div>
                <img
                  className={`mb-2`}
                  style={{ height: '2rem', opacity: '50%' }}
                  src={Timelapse}
                  alt={`Coming Soon`}
                />
                <span className={`text-dark`} style={{ opacity: '50%' }}>
                  Launching 2023
                </span>
              </div>
            </a>
          </li>
        </ul>
      </section>

      <section id={`ventures`} className={`pt-7`}>
        <h2 className={`mb-4`}>Ventures</h2>

        <ul className={`list-unstyled mb-0 row text-center`}>
          <li className={`us-card mb-3`}>
            <a
              href={`https://farmingfirst.com/`}
              aria-label={`Farming First`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={FarmingFirst} alt={`Farming First`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-3`}>
            <a
              href={`https://www.entelligent.com/`}
              aria-label={`Entelligent`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={Entelligent} alt={`Entelligent`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-0`}>
            <a
              href={`https://www.drinklavit.com/`}
              aria-label={`Lavit`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={Lavit} alt={`Lavit`} />
              </div>
            </a>
          </li>
          <li className={`us-card mb-0`}>
            <a
              href={`https://www.neuehouse.com/`}
              aria-label={`Neuehouse`}
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
            >
              <div>
                <img src={NeueHouse} alt={`NeueHouse`} />
              </div>
            </a>
          </li>
        </ul>
      </section>

      <section id={`services`} className={`pt-7`}>
        <h2 className={`mb-4`}>Services</h2>

        <ul className={`list-unstyled list-inline mb-0`}>
          <li className={`list-inline-item mb-4 me-5`}>
            <img
              className={`mb-2`}
              src={CodeSlash}
              alt={`Front End Development`}
            />
            <br />
            <h3 className={`h4 mb-0`}>Front End Development</h3>
          </li>
          <li className={`list-inline-item mb-4 me-5`}>
            <img
              className={`mb-2`}
              src={HomeScreen}
              alt={`Product Design`}
            />
            <br />
            <h3 className={`h4 mb-0`}>Product Design</h3>
          </li>
          <li className={`list-inline-item`}>
            <img
              className={`mb-2`}
              src={Bulb}
              alt={`Strategic Advisory`}
            />
            <br />
            <h3 className={`h4 mb-0`}>Strategic Advisory</h3>
          </li>
        </ul>
      </section>

      <section id={`tools`} className={`pt-7`}>
        <h2 className={`mb-4`}>Tools</h2>

        <div className={`row`}>
          <div className={`col-12 col-md-4 mb-4 mb-md-0`}>
            <h3>Web</h3>
            <ul className={`list-unstyled list-inline mb-0`}>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#00d8ff' }}
              >
                React
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#0070f3' }}
              >
                Next
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#7026B9' }}
              >
                Gatsby
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#b52e31' }}
              >
                Angular
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#3178c6' }}
              >
                Typescript
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#06b5d4' }}
              >
                Tailwind
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#7952b3' }}
              >
                Bootstrap
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#6200ee' }}
              >
                Material
              </li>
            </ul>
          </div>

          <div className={`col-12 col-md-4 mb-4 mb-md-0`}>
            <h3>Mobile</h3>
            <ul className={`list-unstyled list-inline mb-0`}>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#1389FD' }}
              >
                Flutter
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#00d8ff' }}
              >
                React Native
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#4630eb' }}
              >
                Expo
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#3880ff' }}
              >
                Ionic
              </li>
            </ul>
          </div>

          <div className={`col-12 col-md-4`}>
            <h3>Design</h3>
            <ul className={`list-unstyled list-inline mb-0`}>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#5551ff' }}
              >
                Figma
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#0099ff' }}
              >
                Framer
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#31a8ff' }}
              >
                Photoshop
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#d57a01' }}
              >
                Illustrator
              </li>
            </ul>
          </div>

          {/*<div className={`col-12 col-md-8`}>
            <h3>Infrastructure</h3>
            <ul className={`list-unstyled list-inline mb-0`}>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#5468FF' }}
              >
                Algolia
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#3f20ba' }}
              >
                Apollo
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#635dff' }}
              >
                Auth0
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#ff9900' }}
              >
                AWS
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#0b6ae6' }}
              >
                Contentful
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#F57C00' }}
              >
                Firebase
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#4285f4' }}
              >
                Google Cloud
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#054861' }}
              >
                Netlify
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#96bf48' }}
              >
                Shopify
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#635bff' }}
              >
                Stripe
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#24b47e' }}
              >
                Supabase
              </li>
              <li
                className={`badge rounded-pill me-1`}
                style={{ backgroundColor: '#0A72EF' }}
              >
                Vercel
              </li>
            </ul>
          </div>*/}
        </div>
      </section>

      <footer id={`contact`}>
        <div
          className={`footer-content d-flex flex-column justify-content-center`}
          style={{ height: '100vh' }}
        >
          <h2 className={`display-2 mb-4`}>
            Tell us how we can help your business
          </h2>
          <div>
            <a
              className={`btn btn-primary`}
              href={`mailto:contact@understated.co`}
            >
              contact@understated.co
            </a>
          </div>
        </div>
      </footer>
    </main>
  </Layout>
);

export default IndexPage;
